<template>
	<Layout>
		<Header>
			<export-default endpoint="customers" />
		</Header>
		<filter-default
			:filters="filters"
			@filterOrder="filterOrder"
			enable-search
			@filterSearch="filterSearch">
		</filter-default>
		<Error v-if="errored" :back="true" />
		<Results v-if="customers.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline mb-5" tag="div">
			<div v-for="c in customers" :key="c.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block">
					<div class="block__content" @click="modalEdit(c.id)">
						<h3 class="block__name" translate="no">{{ c.name }}</h3>
						<p class="block__email is-ellipsis" translate="no">{{ c.email }}</p>
						<p class="block__email"><span class="capitalize">{{ c.city }}</span> • {{ format(c.created_at) }} • {{ timeTo(c.created_at) }}</p>
					</div>
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Modal from '@/components/modals/Customer'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Placeholder from '@/components/placeholders/User'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { errorToast } from '@/mixins/toast'
import { update } from '@/mixins/modal'
import FilterDefault from '@/components/FilterDefault'
import ExportDefault from '@/components/ExportDefault'

export default {
	name: 'Customer',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Results,
		Error,
		Placeholder,
		FilterDefault,
		ExportDefault
	},
	data() {
		return {
			exporting: false,
			errored: false,
			loading: true,
			pagination: 15,
			customers: [],
			filters: [
				{
					title: 'Ordem',
					function: 'filterOrder',
					options: [
						{ label: 'Nome', value: '1' },
						{ label: 'Data', value: '2' }
					]
				}
			]
		}
	},
    mounted() {
        this.findAll()
    },
	methods: {
        findAll() {
            Api.get('customers/findAll')
                .then(({ data }) => {
					this.customers = data
                })
                .catch(() => {
                    errorToast(this.$t('alerts.load_error'))
                })
				.finally(() => {
					this.loading = false
				})
        },
		filterOrder(e) {
			const type = parseInt(e)
			type === 1 ? this.findAll() : this.findByDate()
		},
		async findByDate() {
			try {
				const response = await Api.get('customers/findAllOrderByDate')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.customers = data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterSearch(v) {
			try {
				if (v.length > 0) {
					const response = await Api.post('customers/findByName', {
						name: v
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.customers = data
					}
				} else {
					await this.findAll()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		modalEdit(id) {
			update(this, 'customer', id, Modal, 'Edit')
		}
	}
}
</script>
