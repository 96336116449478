<template>
    <form autocomplete="off" ref="form">
        <header class="modal-card-head">
            <h4 class="modal-card-title">
                <span><strong>{{ $tc('menu.customers') }}</strong></span>
                <span v-if="customer.id">#{{ customer.id }}</span>
            </h4>
        </header>
        <div class="modal-card-body">
            <b-loading :is-full-page="false" v-model="isOpening"></b-loading>
            <div class="modal-card mb-3">
                <InputWithValidation class="mb-4" name="name" :label="$tc('fields.name', 1)" v-model="customer.name" disabled />
                <InputWithValidation class="mb-4" name="email" :label="$t('fields.email')" v-model="customer.email" disabled />

                <div class="columns">
                    <div class="column">
                        <InputWithValidation :label="$t('fields.document')" v-model="customer.document" disabled />
                    </div>
                    <div class="column">
                        <InputWithValidation :label="$t('fields.phone')" v-model="customer.phone" disabled />
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <InputWithValidation :label="$t('fields.postal_code')" v-model="customer.postal_code" disabled />
                    </div>
                    <div class="column">
                        <InputWithValidation :label="$t('fields.address')" v-model="customer.address" disabled />
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <InputWithValidation :label="$t('fields.city')" v-model="customer.city" disabled />
                    </div>
                    <div class="column">
                        <InputWithValidation :label="$t('fields.state')" v-model="customer.state" disabled />
                    </div>
                </div>

                <small class="modal-updated" v-if="customer.updated_at">{{ $t('labels.last_change') }} {{ format(customer.updated_at) }}</small>
            </div>
        </div>
    </form>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import Api from '@/services/api'
import '@/mixins/generic'

export default {
	components: {
		InputWithValidation
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			customer: {
				email: ''
			},
			role: 1,
			permission: [],
			visible: false
		}
	},
	methods: {
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`customers/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.customer = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
